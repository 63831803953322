var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('vuexy-logo'), _c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v("Qiwari")])], 1), _c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v("Page Not Found 🕵🏻‍♀️")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Oops! 😖 The requested URL was not found on this server. ")]), _c('b-button', {
    staticClass: "mb-2 btn-sm-block",
    attrs: {
      "variant": "primary",
      "to": {
        path: '/'
      }
    }
  }, [_vm._v(" Back to home ")]), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Error page"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }